import { Slider } from '@mui/material'
import { useEffect, useState } from 'react'
import { IAnswer, IQuestion } from '../../data'
import useControls from '../controls'

interface IProps {
  item: IQuestion
}

const SliderQuestion = ({ item }: IProps) => {
  const [selectedAnswer, setSelectedAnswer] = useState<IAnswer | null>(null)
  const { updateSelectedAnswer, ControlsButtonBack } = useControls()
  useEffect(() => updateSelectedAnswer(selectedAnswer), [selectedAnswer])

  let marks: { value: number; label: string }[] = []
  item.answers.forEach((answer) => {
    marks.push({
      value: answer.value,
      label: answer.text || answer.value,
    })
    return
  })

  const handleChange = (value: number | number[]) => {
    item.answers.forEach((item) => {
      if (item.value === value) {
        return setSelectedAnswer(item)
      }
    })
  }

  return (
    <div className={`simulator-question slide ${item.name}`}>
      <div className="simulator-question-title">
        <ControlsButtonBack />
        <h2>{item.text}</h2>
      </div>
      <div className="simulator-question-slider-container">
        <Slider
          className="simulator-question-slider"
          defaultValue={0}
          step={null}
          valueLabelDisplay="off"
          marks={marks}
          min={7}
          max={365}
          onChange={(_e, value) => handleChange(value)}
        />
      </div>
    </div>
  )
}

export default SliderQuestion
