import { useContext, useEffect, useState } from 'react'
import { IAnswer, prices } from '../../data'
import { Box, Button, Divider, Grid } from '@mui/material'
import SimulatorContext, {
  IMetas,
  ITotalPrices,
} from '../../context/SimulatorContext'
import AnswerElement from '../answerElements'
import ContactForm from './components/contactForm'
import { Close } from '@mui/icons-material'
import PDFRender from './components/pdfController'

const RecapSimulation = () => {
  const { simulatorMetas, setSimulatorMetas } = useContext(SimulatorContext)
  const { metas, totalPrice }: { metas: IMetas; totalPrice: ITotalPrices } =
    simulatorMetas
  const [contactIsOpen, setContactIsOpen] = useState<boolean>(false)
  useEffect(() => priceCalculator(), [])

  const priceCalculator = () => {
    let newTotalPrice: ITotalPrices = { totalProduction: 0, totalGlobal: 0 }
    if (!metas.solution) return
    if (metas.solution.value === 'fasteo') {
      if (metas.fasteoPack) {
        newTotalPrice.totalProduction = prices.fasteo[metas.fasteoPack.value]
        newTotalPrice.totalGlobal += prices.fasteo[metas.fasteoPack.value]
      }
    }
    if (metas.ads) {
      if (metas.ads.value) {
        const value: number = prices.ads[metas.ads.value]
        newTotalPrice.totalAds = value
        newTotalPrice.totalGlobal += value
      }
    }
    return setSimulatorMetas({ ...simulatorMetas, totalPrice: newTotalPrice })
  }
  return (
    <div className="recap">
      <h1>Récapitulatif</h1>
      <Divider />
      <ProductionRender metas={metas} totalPrice={totalPrice.totalProduction} />
      <Divider />
      {metas.ads && (
        <AdsRender metas={metas.ads} totalPrice={totalPrice.totalAds} />
      )}
      <Divider />
      <div className="recap-row">
        <div className="recap-row-left">
          <h2>Total</h2>
        </div>
        <div className="recap-row-right">
          {simulatorMetas.totalPrice.totalGlobal > 0
            ? `${simulatorMetas.totalPrice.totalGlobal.toLocaleString('fr', {
                minimumFractionDigits: 2,
              })}
          € HT`
            : `Nous ne pouvons vous faire une estimation.
             Cliquez sur "Demander un devis" pour avoir plus d'informations`}
        </div>
      </div>
      <div className="recap-contact-actions">
        <Button variant="contained" onClick={() => setContactIsOpen(true)}>
          Demander un devis
        </Button>
        {/* <Button variant="contained">Recommencer la simulation</Button> */}
      </div>
      <Box
        className={`recap-contact ${contactIsOpen ? 'open' : 'closed'}`}
        sx={{
          backgroundColor: 'background.default',
        }}
      >
        <Grid container>
          <Grid item lg={6} xs={12}>
            <ContactForm onClose={() => setContactIsOpen(false)} />
          </Grid>
          <Grid item lg={6}>
            {/* <PDFRender /> */}
          </Grid>
        </Grid>
        <div className="recap-contact-drawer-close">
          <Button endIcon={<Close />} onClick={() => setContactIsOpen(false)}>
            Fermer
          </Button>
        </div>
      </Box>
    </div>
  )
}

const ProductionRender = ({
  metas,
  totalPrice,
}: {
  metas: IMetas
  totalPrice: number
}) =>
  metas.solution && (
    <div className="recap-row">
      <div className="recap-row-left">
        {metas.solution.value === 'fasteo' &&
          metas.fasteoPack &&
          metas.fasteoPack.elements &&
          metas.fasteoPack.elements.map((element) => (
            <AnswerElement element={element} />
          ))}
        {metas.solution.value !== 'fasteo' && (
          <div>
            <h2>Nemesia Production</h2>
            <h3>Votre sélection: </h3>
            <p>
              <b>Type de vidéo selectionné:</b>{' '}
              {metas.videoType && metas.videoType.value}
            </p>
            <p>
              <b>Durée de la vidéo estimée:</b>{' '}
              {metas.videoDuration && metas.videoDuration.value}
            </p>
            <p>
              Les prestations Nemesia Production sont faites sur-mesure, nous ne
              pouvons donc pas simuler son coût.
            </p>
          </div>
        )}
      </div>
      <div className="recap-row-right">
        {metas.solution.value === 'fasteo'
          ? `${totalPrice.toLocaleString('fr', {
              minimumFractionDigits: 2,
            })} € HT`
          : 'A définir'}
      </div>
    </div>
  )

const AdsRender = ({
  metas,
  totalPrice,
}: {
  metas: IAnswer | null | undefined
  totalPrice: number | undefined
}) => {
  if (!metas) return <div />
  return (
    <div className="recap-row">
      <div className="recap-row-left">
        <h2>Campagne Ads (hors budget de diffusion)</h2>
        <p>Durée: {metas.value ? metas.text : 'A définir'}</p>
      </div>
      <div className="recap-row-right">
        {metas.value && totalPrice
          ? `${totalPrice.toLocaleString('fr', {
              minimumFractionDigits: 2,
            })} € HT`
          : 'A définir'}
      </div>
    </div>
  )
}

export default RecapSimulation
