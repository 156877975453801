import { useEffect, useState } from 'react'
import { IAnswer, IQuestion } from '../../data'
import AnswerElement from '../answerElements'
import useControls from '../controls'
import { Done, Info } from '@mui/icons-material'
import { Box, Button, Drawer, IconButton, Typography } from '@mui/material'

interface IProps {
  item: IQuestion
}

const DrawerContent = ({ data }: { data: IAnswer | null }) => {
  if (!data) {
    return (
      <Box role="presentation" className="multiBlocs-drawer-content">
        Aucune information
      </Box>
    )
  }
  return (
    <Box role="presentation" className="multiBlocs-drawer-content">
      <div className="simulator-question-title">
        <Typography component="h5" variant="h5">
          {data.text}
        </Typography>
      </div>
      <div className="multiBlocs-drawer-content-media">
        {data.elements &&
          data.elements.map((element) => (
            <AnswerElement element={element} key={element.type} />
          ))}
      </div>
    </Box>
  )
}
const MultiBlocsQuestion = ({ item }: IProps) => {
  const [selectedAnswer, setSelectedAnswer] = useState<IAnswer | null>(null)
  const [informations, setInformations] = useState<IAnswer | null>(null)
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
  const { updateSelectedAnswer, ControlsButtonBack } = useControls()
  const [isIdkBtnSelected, setIsIdkBtnSelected] = useState<boolean>(false)
  useEffect(() => updateSelectedAnswer(selectedAnswer), [selectedAnswer])

  const handleChange = (answer: IAnswer | undefined) => {
    if (!answer) return
    !answer.value ? setIsIdkBtnSelected(true) : setIsIdkBtnSelected(false)
    if (answer === selectedAnswer) return setSelectedAnswer(null)
    return setSelectedAnswer(answer)
  }

  const handleInfos = (answer: IAnswer) => {
    setInformations(answer)
    setDrawerOpen(true)
  }
  const closeDrawerInfos = (e: MouseEvent) => {
    e.preventDefault()
    setInformations(null)
    setDrawerOpen(false)
  }
  return (
    <>
      <div className={`simulator-question multiBlocs ${item.name}`}>
        <div className="simulator-question-title">
          <ControlsButtonBack />
          <h2>{item.text}</h2>
        </div>
        <div className="multiBlocs-list">
          {item.answers.map((answer) => (
            <div
              className={`multiBlocs-list-item glassmorphism ${
                selectedAnswer &&
                answer.value === selectedAnswer.value &&
                'active'
              }`}
              key={answer.text}
              onClick={() => handleChange(answer)}
            >
              {answer.elements &&
                answer.elements.map((element) => (
                  <AnswerElement element={element} key={element.type} />
                ))}
              {selectedAnswer && answer.value === selectedAnswer.value && (
                <div className="multiBlocs-isActive">
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '5px',
                    }}
                  >
                    <Done />
                  </span>
                </div>
              )}
              <IconButton
                color="primary"
                component="span"
                className="multiBlocs-drawer-content-infos"
                onClick={() => handleInfos(answer)}
              >
                <Info />
              </IconButton>
            </div>
          ))}
        </div>
        {item.idkBtn && (
          <div className="idkBtn">
            <div className="idkBtn-container">
              <Button
                variant="contained"
                className={`idkBtn-btn ${isIdkBtnSelected ? 'selected' : ''}`}
                onClick={() => handleChange(item.idkBtn)}
                startIcon={isIdkBtnSelected && <Done />}
              >
                Je ne sais pas
              </Button>
            </div>
          </div>
        )}
      </div>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={closeDrawerInfos}
        className="multiBlocs-drawer"
      >
        <DrawerContent data={informations} />
      </Drawer>
    </>
  )
}

export default MultiBlocsQuestion
