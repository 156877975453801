import { data, IQuestion } from '../data'
import RecapSimulation from './recap'
import BooleanQuestion from './questionsBlocs/booleanQuestion'
import SelectBlocQuestion from './questionsBlocs/selectBlocQuestion'
import SliderQuestion from './questionsBlocs/sliderQuestion'
import useControls from './controls'
import { useContext } from 'react'
import SimulatorContext from '../context/SimulatorContext'
import SimulatorStepper from './stepper'
import MultipleButtonQuestion from './questionsBlocs/mulitpleButtonQuestions'
import MultiBlocsQuestion from './questionsBlocs/multiBlocsQuestion'
import InputQuestion from './questionsBlocs/inputQuestion'
import SwitchQuestion from './questionsBlocs/switchQuestion'

const SimulatorRender = () => {
  const { simulatorMetas } = useContext(SimulatorContext)
  const { questionId } = simulatorMetas
  if (questionId === 'finish') return <RecapSimulation />
  let currentQuestion: IQuestion = data[0]
  data.forEach((item) => {
    if (item.id === questionId) return (currentQuestion = item)
    return
  })
  return (
    <>
      <SimulationBloc question={currentQuestion} />
      <SimulatorStepper step={currentQuestion.step} />
    </>
  )
}

const SimulationBloc = ({ question }: { question: IQuestion }) => {
  const { type } = question
  switch (type) {
    case 'boolean':
      return <BooleanQuestion item={question} />
    case 'switch':
      return <SwitchQuestion item={question} />
    case 'multipleButton':
      return <MultipleButtonQuestion item={question} />
    case 'selectBloc':
      return <SelectBlocQuestion item={question} />
    case 'multipleBlocs':
      return <MultiBlocsQuestion item={question} />
    case 'slider':
      return <SliderQuestion item={question} />
    case 'input':
      return <InputQuestion item={question} />
    case 'title':
      return <SliderQuestion item={question} />
    default:
      return <div>{question.text}</div>
  }
}

const Simulator = () => {
  const { ControlsButtonValidation } = useControls()
  return (
    <div className="simulator">
      <SimulatorRender />
      <ControlsButtonValidation />
    </div>
  )
}

export default Simulator
