import { useState } from 'react'
import './assets/SCSS/main.scss'
import SimulatorContext, {
  INITIAL_SIMULATOR,
  ISimulator,
} from './context/SimulatorContext'
import Simulator from './components'
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material'
import TopBar from './components/topBar'

const mainColor: string = '#9d4edd'
const darkThemePreference: boolean = window.matchMedia(
  '(prefers-color-scheme: dark)'
).matches
function App() {
  const [simulatorMetas, setSimulatorMetas] =
    useState<ISimulator>(INITIAL_SIMULATOR)
  const [isDark, setIsDark] = useState<boolean>(darkThemePreference)
  const lightTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: mainColor,
      },
      secondary: {
        main: '#111111',
      },
      text: {
        primary: '#111111',
      },
    },
  })
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: mainColor,
      },
      secondary: {
        main: '#ffffff',
      },
      background: {
        default: '#111111',
      },
      text: {
        primary: '#ffffff',
      },
    },
  })
  return (
    <ThemeProvider theme={isDark ? darkTheme : lightTheme}>
      <CssBaseline />
      <SimulatorContext.Provider value={{ simulatorMetas, setSimulatorMetas }}>
        <div
          className="App"
          style={{ background: "url('/media/waves-background.png')" }}
        >
          <TopBar setIsLight={() => setIsDark(!isDark)} isLight={isDark} />
          <Simulator />
        </div>
      </SimulatorContext.Provider>
    </ThemeProvider>
  )
}

export default App
