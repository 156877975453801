import { ArrowBack } from '@mui/icons-material'
import { IconButton, Button, Divider, Box } from '@mui/material'
import { LightMode, DarkMode } from '@mui/icons-material'

const TopBar = ({
  setIsLight,
  isLight,
}: {
  setIsLight: any
  isLight: boolean
}) => {
  const queryParams = new URLSearchParams(window.location.search)
  const from = queryParams.get('from')
  return (
    <>
      <Box className="topBar">
        <a href={from || ''} className="topBar-link">
          <Button
            startIcon={<ArrowBack />}
            color="secondary"
            className="topBar-link-btn"
          >
            Retourner sur le site
          </Button>
        </a>
        <IconButton onClick={setIsLight} className="topBar-themeMode">
          {isLight ? <LightMode /> : <DarkMode />}
        </IconButton>
      </Box>
      <Divider />
    </>
  )
}

export default TopBar
