import { TextField } from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'
import { IAnswer, IQuestion } from '../../data'
import useControls from '../controls'

interface IProps {
  item: IQuestion
}

const InputQuestion = ({ item }: IProps) => {
  const [formData, setFormData] = useState<IAnswer | null>(null)
  const { updateSelectedAnswer, ControlsButtonBack } = useControls()
  const { answers } = item

  useEffect(() => updateSelectedAnswer(formData), [formData])
  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target
    const updatedData: IAnswer = { ...item.answers[0], value }
    return setFormData(updatedData)
  }
  return (
    <div className={`simulator-question input ${item.name}`}>
      <div className="simulator-question-title">
        <ControlsButtonBack />
        <h2>{item.text}</h2>
      </div>
      {answers.map((answer, index) => (
        <div className="input-container glassmorphism" key={index}>
          <TextField
            type={answer.type}
            name={answer.name}
            label={answer.text}
            variant="standard"
            className="input-item-element"
            onChange={handleChange}
          />
        </div>
      ))}
    </div>
  )
}

export default InputQuestion
