import { useEffect, useState } from 'react'
import { IAnswer, IQuestion } from '../../data'
import AnswerElement from '../answerElements'
import useControls from '../controls'
import { Done } from '@mui/icons-material'
import { Button } from '@mui/material'
interface IProps {
  item: IQuestion
}

const SelectBlocQuestion = ({ item }: IProps) => {
  const [selectedAnswer, setSelectedAnswer] = useState<IAnswer | null>(null)
  const { updateSelectedAnswer, ControlsButtonBack } = useControls()
  const [isIdkBtnSelected, setIsIdkBtnSelected] = useState<boolean>(false)
  useEffect(() => updateSelectedAnswer(selectedAnswer), [selectedAnswer])

  const handleChange = (answer: IAnswer | undefined) => {
    if (!answer) return
    !answer.value ? setIsIdkBtnSelected(true) : setIsIdkBtnSelected(false)
    if (answer === selectedAnswer) return setSelectedAnswer(null)
    return setSelectedAnswer(answer)
  }
  return (
    <div className={`simulator-question selectBloc ${item.name}`}>
      <div className="simulator-question-title">
        <ControlsButtonBack />
        <h2>{item.text}</h2>
      </div>
      <div className="selectBloc-list">
        {item.answers.map((answer) => (
          <div
            className={`selectBloc-list-item glassmorphism ${
              selectedAnswer &&
              answer.value === selectedAnswer.value &&
              'active'
            }`}
            key={answer.text}
            onClick={() => handleChange(answer)}
          >
            {answer.elements &&
              answer.elements.map((element) => (
                <AnswerElement element={element} key={element.type} />
              ))}
            {selectedAnswer && answer.value === selectedAnswer.value && (
              <div className="selectBloc-isActive">
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '5px',
                  }}
                >
                  <Done />
                </span>
              </div>
            )}
          </div>
        ))}
      </div>
      {item.idkBtn && (
        <div className="idkBtn">
          <div className="idkBtn-container">
            <Button
              variant="contained"
              className={`idkBtn-btn ${isIdkBtnSelected ? 'selected' : ''}`}
              onClick={() => handleChange(item.idkBtn)}
              startIcon={isIdkBtnSelected && <Done />}
            >
              Je ne sais pas
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default SelectBlocQuestion
