import { useTheme } from '@mui/material'
import { IAnswersElement } from '../data'

interface IProps {
  element: IAnswersElement
}

const AnswerElement = ({ element }: IProps) => {
  switch (element.type) {
    case 'img':
      return <ImgElement element={element} />
    case 'youtube':
      return <YouTubeElement element={element} />
    case 'text':
      return <TextElement element={element} />
    case 'listText':
      return <ListTextElement element={element} />
    case 'title':
      return <TitleElement element={element} />
    default:
      return <div />
  }
}

const ImgElement = ({ element }: { element: IAnswersElement }) => {
  const { palette } = useTheme()
  const { mode } = palette
  console.log(element)
  return (
    <div className="element-img">
      <img
        src={mode === 'dark' ? element.alt : element.value}
        alt={element.type}
      />
    </div>
  )
}

const VideoElement = ({ element }: { element: IAnswersElement }) => (
  <video src={element.value} />
)
const YouTubeElement = ({ element }: { element: IAnswersElement }) => (
  <iframe
    width="100%"
    frameBorder={0}
    src={element.value}
    title="video"
    className="video-playerYouTube"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
)
const TitleElement = ({ element }: { element: IAnswersElement }) => (
  <h3>{element.value}</h3>
)
const TextElement = ({ element }: { element: IAnswersElement }) => (
  <p>{element.value}</p>
)

export const ListTextElement = ({ element }: { element: IAnswersElement }) => {
  return (
    <ol className="listText-list">
      {element.value.map((item: string, i: number) => (
        <li key={i} className="listText-list-item">
          {item}
        </li>
      ))}
    </ol>
  )
}

export default AnswerElement
