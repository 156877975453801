import { createContext, SetStateAction } from 'react'
import { IAnswer } from '../data';

export interface ISimulator {
  questionId: number | "finish";
  history: number[]
  step: number;
  metas: IMetas;
  selectedAnswer?: IAnswer | null
  totalPrice: ITotalPrices;
}
export interface IMetas {
  solution: IAnswer | null,
  videoType?: IAnswer
  videoDuration?: IAnswer
  fasteoPack?: IAnswer | null,
  localisation: any | null,
  ads: IAnswer | null
}

export interface ITotalPrices {
  totalProduction: number
  totalAds?: number
  totalGlobal: number
}

export const INITIAL_SIMULATOR: ISimulator = {
  questionId: 0,
  history: [],
  step: 0,
  metas: {
    solution: null,
    ads: null,
    localisation: null,
  },
  totalPrice: { totalProduction: 0, totalGlobal: 0 }
}

const context = {
  simulatorMetas: INITIAL_SIMULATOR,
  setSimulatorMetas: (simulatorMetas: SetStateAction<ISimulator>) => {
  },
}

const SimulatorContext = createContext(context)
export default SimulatorContext
