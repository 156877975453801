import { Step, StepContent, StepLabel, Stepper } from '@mui/material'

const SimulatorStepper = ({ step }: { step: number }) => {
  const steps: number[] = Array.from(Array(5).keys())
  return (
    <div className="simulator-stepper">
      <Stepper activeStep={step - 1} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step}>
            <StepLabel></StepLabel>
            <StepContent></StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  )
}

export default SimulatorStepper
