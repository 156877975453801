export interface IAnswer {
  text?: string
  type?: string
  value?: any | null
  name?: string
  parentId: number
  elements?: IAnswersElement[]
  redirect: number | "finish"
}

export interface IAnswersElement {
  type: string;
  value: any;
  alt?: any;
}

export interface IQuestion {
  id: number
  name: string
  text: string
  type: string
  step: number
  idkBtn?: IAnswer
  elements?: IAnswersElement[]
  answers: IAnswer[],
}

export const data: IQuestion[] = [
  {
    "id": 0,
    "step": 1,
    "name": "solutionSelector",
    "type": "selectBloc",
    "text": "De quelle solution avez-vous besoin ?",
    answers: [
      {
        text: "Fasteo",
        name: "solution",
        value: "fasteo",
        elements: [{ type: "img", value: "/media/fasteo-logo.svg", alt: "/media/fasteo-logo-white.svg" }, { type: "text", value: "Votre vidéo en moins de 48h" }],
        redirect: 1,
        parentId: 0,
      },
      {
        text: "Nemesia Production",
        name: "solution",
        value: "production",
        elements: [{ type: "img", value: "/media/nemesia-logo.svg", alt: "/media/nemesia-logo-white.svg" }, { type: "text", value: "Votre vidéo sur-mesure" }],
        redirect: 6,
        parentId: 0,
      }
    ]
  },
  {
    "id": 1,
    "step": 2,
    "name": "fasteoSelectPack",
    "type": "selectBloc",
    "text": "Quel pack est le plus adapté a votre situation ?",
    answers: [
      {
        text: "Clip Promotionnel",
        name: "fasteoPack",
        value: "promo",
        elements: [{ type: "title", value: "Clip Promotionnel" },
        { type: "text", value: "Le clip promotionnel permet de promouvoir votre activité, un service ou une action menée au sein de votre structure (gala, conférence, soirée d'entreprise,...) grâce à une vidéo rythmée et percutante." },
        {
          type: "listText", value: [
            "Tournée et montée en moins de 48h",
            "Plan d’illustration",
            "Droits musicaux",
            "Titrage",
            "1 révision mineure"]
        }],
        parentId: 1,
        redirect: 4
      },
      {
        text: "Reportage",
        name: "fasteoPack",
        value: "reportage",
        parentId: 1,
        elements: [{ type: "title", value: "Reportage" },
        { type: "text", value: "Bénéficiez d’un reportage publicitaire court et efficace présentant, grâce à une interview et des plans d’illustrations, votre activité !" },
        { type: "listText", value: ["Tournée et montée en moins de 48h", "Plan d’illustration", "1 révision mineure", "Titrage", "Droits musicaux"] }
        ],
        redirect: 4
      }
    ]
  },
  {
    "id": 2,
    "step": 3,
    "name": "localisation",
    "type": "localisation",
    "text": "Où serait localisé le tournage ?",
    answers: [
      {
        redirect: 4,
        parentId: 2,
      }
    ]
  },
  {
    "id": 3,
    "step": 3,
    "name": "date",
    "type": "date",
    "text": "date",
    answers: [
      {
        redirect: 4,
        parentId: 3,
      },
    ]

  },
  {
    "id": 4,
    "step": 4,
    "name": "booleanNemesiaAds",
    "type": "switch",
    "text": "Souhaitez-vous diffuser votre vidéo via Nemesia Ads ?",
    elements: [{ type: "img", value: "/media/nemesiaAds-logo.svg", alt: "/media/nemesiaAds-logo-white.svg" }, { type: "text", value: "Nemesia Ads vous permet de cibler vos potentiels clients grâce à des campagnes Ads vidéo diffusées sur les réseaux sociaux et sur internet." }, { type: "text", value: "Besoin de Nemesia Ads ?" }],
    answers: [
      {
        text: "Oui",
        value: true,
        name: "ads",
        redirect: 5,
        parentId: 4,
      },
      {
        text: "Non",
        name: "ads",
        value: false,
        redirect: "finish",
        parentId: 4,
      }
    ]
  },
  {
    "id": 5,
    "step": 4,
    "type": "multipleButton",
    "name": "nemesiaAdsDuration",
    "text": "Quelle est la durée de la campagne ?",
    elements: [{ type: "text", value: "La durée de la campagne influera sur le coût et sur le budget diffusion qui sera allouée à votre campagne. Si vous ne savez pas répondre à cette question, pas de problème nous en discuterons avec vous après cette simulation" }],
    answers: [
      {
        text: "7 jours",
        name: "campaignDuration",
        value: 7,
        redirect: "finish",
        parentId: 5,
      },
      {
        text: "1 mois",
        name: "campaignDuration",
        value: 30,
        redirect: "finish", parentId: 5,

      },
      {
        text: "3 mois",
        name: "campaignDuration",
        value: 90,
        redirect: "finish",
        parentId: 5,
      },
      {
        text: "6 mois",
        name: "campaignDuration",
        value: 120,
        redirect: "finish", parentId: 5,

      },
      {
        text: "1 an",
        name: "campaignDuration",
        value: 365,
        redirect: "finish", parentId: 5,
      },
      {
        text: "Je ne sais pas",
        name: "campaignDuration",
        value: null,
        redirect: "finish", parentId: 5,
      }
    ]
  },
  {
    "id": 6,
    "step": 2,
    "name": "videoType",
    "type": "multipleBlocs",
    // "idkBtn": { text: "Je ne sais pas", redirect: 7, parentId: 6 },
    "text": "Quel style vous correspond ?",
    elements: [{ type: "text", value: "Nemesia Ads vous permet de cibler vos potentiels clients grâce à des campagnes Ads vidéo diffusées sur les réseaux sociaux et sur internet." }],
    answers: [
      {
        text: "Court-métrage publicitaire",
        value: "Vidéo Publicitaire",
        name: "videoType",
        elements: [{ type: "youtube", value: "https://www.youtube.com/embed/9elUkhYjrYk" }, { type: "title", value: "Court-métrage publicitaire" }, { type: "text", value: "L'idée est de présenter et promouvoir votre activité avec une vidéo originale combinant mise en scène, scénario et émotion. Grâce à une vidéo d'une durée variable, pouvant aller de 15 secondes à plusieurs minutes, vous aurez la capacité de vous démarquer et de marquer les esprits." }],
        redirect: 7,
        parentId: 6,
      },
      {
        text: "Documentaire Publicitaire",
        value: "Documentaire Publicitaire",
        name: "videoType",
        elements: [{ type: "youtube", value: "https://www.youtube.com/embed/JK4s7v4ApZc" }, { type: "title", value: "Documentaire Publicitaire" }, { type: "text", value: "L'objectif d'un documentaire publicitaire, est de raconter l'histoire de votre entreprise, la présenter sous des traits francs et directs. La forme du documentaire offre la possibilité de faire du cinéma-vérité, de présenter de façon humaine votre activité, à travers des témoignages de collaborateurs et de clients. Le documentaire donne à votre entreprise une image authentique et proche de ses clients." }],
        redirect: 7,
        parentId: 6,
      },
      {
        text: "Clip Promotionnel",
        value: "Clip Promotionnel",
        name: "videoType",
        elements: [{ type: "youtube", value: "https://www.youtube.com/embed/fmOodp-tIl8" }, { type: "title", value: "Clip Promotionnel" }, { type: "text", value: "Le clip promotionnel permet de promouvoir une action menée par votre structure. Vous offrez ainsi aux participants de vos actions un souvenir inoubliable et l'occasion au public de les découvrir." }],
        redirect: 7,
        parentId: 6,
      },
      {
        text: "Live",
        value: "Live",
        name: "videoType",
        elements: [{ type: "img", value: "/media/nemesia-live.webp", alt: '/media/nemesia-live.webp' }, { type: "title", value: "Live" }, { type: "text", value: "Diffusez en direct sur internet vos actions (compétitions sportives, conférences, concert, ...)." }],
        redirect: 7,
        parentId: 6,
      },
      {
        text: "Animation",
        value: "Animation",
        name: "videoType",
        elements: [{ type: "youtube", value: "https://www.youtube.com/embed/qzyNzGwIYlY" }, { type: "title", value: "Animation" }, { type: "text", value: "Mettez en avant votre activité grâce à une animation 2D ou 3D, vous offrant la possibilité de présenter votre activité de façon originale." }],
        redirect: 7,
        parentId: 6,
      },
      {
        text: "Web série/Emission",
        value: "Web série/Emission",
        name: "videoType",
        elements: [{ type: "youtube", value: "https://www.youtube.com/embed/KjU64k0wSd0" }, { type: "title", value: "Web série/Emission" }, { type: "text", value: "Vous avez plein de choses à raconter ? Des choses à partager ? Développer votre propre émission et partagez là sur Youtube (tutoriels, unboxing, Story time)." }],
        redirect: 7,
        parentId: 6,
      },
    ]
  },
  {
    "id": 7,
    "step": 3,
    "name": "videoDuration",
    "type": "input",
    "idkBtn": { text: "Je ne sais pas", redirect: 4, parentId: 7 },
    "text": "Quelle durée estimez-vous nécessaire pour votre vidéo ?",
    answers: [{
      text: "Durée de la vidéo",
      name: "videoDuration",
      value: "",
      redirect: 4,
      parentId: 7,
    }]
  },

]

interface IPrice {
  [n: string]: { [i: string | number]: number }
}

export const prices: IPrice = {
  fasteo: { promo: 795, reportage: 860 },
  ads: { 7: 259, 30: 350, 90: 630, 120: 1050, 365: 1610 },
}
