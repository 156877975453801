import { Switch } from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'
import { IAnswer, IQuestion } from '../../data'
import AnswerElement from '../answerElements'
import useControls from '../controls'

interface IProps {
  item: IQuestion
}

const SwitchQuestion = ({ item }: IProps) => {
  const [selectedAnswer, setSelectedAnswer] = useState<IAnswer | null>(
    item.answers[0]
  )
  const { updateSelectedAnswer, ControlsButtonBack } = useControls()
  useEffect(() => updateSelectedAnswer(selectedAnswer), [selectedAnswer])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target
    let updatedValue: IAnswer | null = null
    item.answers.forEach((answer) => {
      if (answer.value === checked) return (updatedValue = answer)
    })
    return setSelectedAnswer(updatedValue)
  }
  return (
    <div className={`simulator-question switch ${item.name}`}>
      <div className="simulator-question-title">
        <ControlsButtonBack />
        <h2>{item.text}</h2>
      </div>
      <div>
        {item.elements && (
          <div className="simulator-question-elements">
            {item.elements.map((element) => (
              <AnswerElement element={element} />
            ))}
          </div>
        )}
      </div>
      <div className="switch-element glassmorphism">
        Non
        <Switch defaultChecked onChange={handleChange} />
        Oui
      </div>
    </div>
  )
}

export default SwitchQuestion
