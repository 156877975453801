import { memo, useContext, useEffect, useState } from 'react'
import { IAnswer } from '../data'
import { IconButton } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import SimulatorContext, { ISimulator } from '../context/SimulatorContext'

export default function useControls() {
  const { simulatorMetas, setSimulatorMetas } = useContext(SimulatorContext)
  const { selectedAnswer } = simulatorMetas

  const nextQuestion = () => {
    let newState: ISimulator
    if (!selectedAnswer) return
    const { name, redirect } = selectedAnswer
    if (name) {
      let updatedMeta: any = { ...simulatorMetas.metas, [name]: selectedAnswer }
      if (name === 'campaignDuration') {
        updatedMeta = {
          ...simulatorMetas.metas,
          ads: selectedAnswer,
        }
      }
      newState = {
        ...simulatorMetas,
        questionId: redirect,
        history: [...simulatorMetas.history, selectedAnswer.parentId],
        metas: updatedMeta,
        selectedAnswer: null,
      }
    } else {
      newState = {
        ...simulatorMetas,
        questionId: redirect,
        selectedAnswer: null,
      }
    }
    return setSimulatorMetas(newState)
  }

  const updateSelectedAnswer = (selectedAnswer: IAnswer | null) => {
    if (!selectedAnswer) {
      return setSimulatorMetas({
        ...simulatorMetas,
        selectedAnswer: null,
      })
    }
    return setSimulatorMetas({
      ...simulatorMetas,
      selectedAnswer: { ...selectedAnswer },
    })
  }

  const MemoControlsButtonValidation = () => {
    const [previousState, setPreviousState] = useState<boolean>(false)
    const [isOpen, setIsOpen] = useState<boolean>(previousState)
    const selectedAnswerExist: boolean =
      simulatorMetas.selectedAnswer && 'text' in simulatorMetas.selectedAnswer
        ? true
        : false
    useEffect(() => {
      if (
        simulatorMetas.selectedAnswer &&
        selectedAnswerExist !== previousState
      ) {
        if (simulatorMetas.selectedAnswer) {
          setIsOpen(true)
          setPreviousState(true)
        } else {
          setIsOpen(false)
          setPreviousState(false)
        }
      }
    }, [isOpen, previousState])

    return (
      <div onClick={nextQuestion}>
        <div className={`controls-validateBtn ${isOpen ? 'open' : 'closed'}`}>
          Suivant
        </div>
      </div>
    )
  }

  const ControlsButtonValidation = memo(MemoControlsButtonValidation)

  const ControlsButtonBack = () => {
    const { history } = simulatorMetas
    const handleBack = () => {
      const lastQuestion = history[history.length - 1]
      history.pop()
      return setSimulatorMetas({
        ...simulatorMetas,
        questionId: lastQuestion,
        selectedAnswer: null,
        history,
      })
    }
    if (history.length < 1) return <div />
    return (
      <IconButton onClick={handleBack}>
        <ArrowBack />
      </IconButton>
    )
  }

  return {
    nextQuestion,
    ControlsButtonValidation,
    ControlsButtonBack,
    updateSelectedAnswer,
  }
}
