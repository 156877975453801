import { Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { IAnswer, IQuestion } from '../../data'
import AnswerElement from '../answerElements'
import useControls from '../controls'

interface IProps {
  item: IQuestion
}

const MultipleButtonQuestion = ({ item }: IProps) => {
  const [selectedAnswer, setSelectedAnswer] = useState<IAnswer | null>(null)
  const { updateSelectedAnswer, ControlsButtonBack } = useControls()
  useEffect(() => updateSelectedAnswer(selectedAnswer), [selectedAnswer])

  const handleChange = (answer: IAnswer) => setSelectedAnswer(answer)

  return (
    <div className={`simulator-question multipleButton ${item.name}`}>
      <div className="simulator-question-title">
        <ControlsButtonBack />
        <h2>{item.text}</h2>
      </div>
      <div>
        {item.elements && (
          <div className="simulator-question-elements">
            {item.elements.map((element) => (
              <AnswerElement element={element} />
            ))}
          </div>
        )}
      </div>
      <div className="multipleButton-list">
        {item.answers.map((answer) => (
          <Button
            key={answer.text}
            variant="outlined"
            className={`btn ${selectedAnswer === answer ? 'active' : null}`}
            onClick={() => handleChange(answer)}
          >
            {answer.text}
          </Button>
        ))}
      </div>
    </div>
  )
}

export default MultipleButtonQuestion
