import { Alert, AlertTitle, Button, Grid, TextField } from '@mui/material'
import emailjs from '@emailjs/browser'
import ReCAPTCHA from 'react-google-recaptcha'
import React, { ChangeEvent, useContext, useState } from 'react'
import SimulatorContext from '../../../context/SimulatorContext'

const siteKey: string = '6LcMYxYgAAAAAKgD4GbBMVnevMIFN_6IwK-p_9dj'

interface IContactForm {
  email: string | null
  lastName: string | null
  firstName: string | null
  companyName: string | null
  message: string | null
}

interface IAlert {
  type: 'error' | 'success'
  message: string
  description: string
}

interface IProps {
  onClose: any
}

const ContactForm = ({ onClose }: IProps) => {
  const { simulatorMetas } = useContext(SimulatorContext)
  const [contactForm, setContactForm] = useState<IContactForm>({
    email: null,
    lastName: null,
    firstName: null,
    companyName: null,
    message: null,
  })
  const [showCaptcha, setShowCaptcha] = useState<boolean>(false)
  const [isValid, setIsValid] = useState<boolean>(false)
  const [alert, setAlert] = useState<IAlert | null>(null)

  const checkIsValid = (updatedContactForm: IContactForm) => {
    if (!updatedContactForm) return
    const { email, firstName, lastName } = updatedContactForm
    if (email && firstName && lastName) {
      setIsValid(true)
    } else if (isValid) {
      setIsValid(false)
    }
  }

  const sendEmail = async (token: string | null) => {
    try {
      // if (!token) throw new Error(`Invalid token`)
      const params = {
        ...contactForm,
        ...simulatorMetas,
        // 'g-recaptcha-response': token,
      }
      await emailjs.send(
        'service_tb9h8rd',
        'template_xdf0e0m',
        params,
        'SAprzMSSpnPYMkUbZ'
      )
      setAlert({
        type: 'success',
        message: 'Message envoyée',
        description:
          'Votre message a été envoyé avec succès ! Nous reviendrons vers vous dans les plus brefs délais',
      })
      setTimeout(() => {
        setAlert(null)
      }, 3000)
    } catch (err) {
      setAlert({
        type: 'error',
        message: 'Erreur',
        description:
          'Une erreur est survenue, merci de nous envoyer votre message directement par email',
      })
    }
    setShowCaptcha(false)
  }

  const handleSubmit = (e: React.MouseEvent) => {
    e.preventDefault()
    const { email, firstName, lastName, message } = contactForm
    if (email && firstName && lastName && message) {
      // setShowCaptcha(true)
      sendEmail(null)
    }
  }

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target
    const updatedContactForm: IContactForm = { ...contactForm, [name]: value }
    setContactForm(updatedContactForm)
    checkIsValid(updatedContactForm)
  }
  return (
    <div className="recap-contact-form">
      {alert && (
        <Alert severity={alert.type}>
          <AlertTitle>{alert.message}</AlertTitle>
          {alert.description}
        </Alert>
      )}

      {!showCaptcha ? (
        <>
          <h1>Nous contacter</h1>
          <TextField
            placeholder="Email"
            required
            name="email"
            onChange={handleChange}
          />
          <TextField
            placeholder="Prénom"
            name="firstName"
            required
            onChange={handleChange}
          />
          <TextField
            placeholder="Nom"
            name="lastName"
            required
            onChange={handleChange}
          />
          <TextField
            placeholder="Nom de votre structure"
            name="companyName"
            onChange={handleChange}
          />
          <TextField
            name="message"
            multiline
            rows={4}
            onChange={handleChange}
            placeholder="Votre message..."
          />
          <div className="recap-contact-form-actions">
            <Button
              className="btn-submit"
              variant="contained"
              onClick={handleSubmit}
              disabled={!isValid}
            >
              Envoyer
            </Button>
          </div>
        </>
      ) : (
        <ReCAPTCHA sitekey={siteKey} onChange={sendEmail} />
      )}
    </div>
  )
}

export default ContactForm
